import RootStore from "./RootStore";
import {action, autorun, makeAutoObservable, observable} from "mobx";
import {Project} from "../models/Project";
import ProjectUser from "../models/ProjectUser";
import ProjectService from "../service/ProjectService";

export class ProjectStore {

    @observable
    public projects: Project[] = [];
    @observable
    public project?: Project;

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this)
        autorun(() => {
            if (this.rootStore.userStore.user) {
                this.loadProjects(this.rootStore.userStore.user).catch(console.error);
            }
        })
    }

    @action
    async loadProjects(user: ProjectUser) {
        this.projects = await ProjectService.getProjects(user);
    }

    @action
    async saveProject(project: Project, data: any) {
        await ProjectService.updateProject(project, data);
    }

    @action
    async createProject(user: ProjectUser, name: any) {
        const projectId = `${user.uid}-${name}`;
        const project = new Project({name: name, owner: user, id: projectId});

        await ProjectService.createProject(project)

        this.projects.push(project);
        this.project = project;
    }

    @action
    async loadProject(id: string) {
        this.project = await ProjectService.getProject(id, this.rootStore.userStore.user!);
    }
}