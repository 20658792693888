import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {ChakraProvider, ColorModeScript, createStandaloneToast} from "@chakra-ui/react";
import theme from "./theme";

const {ToastContainer} = createStandaloneToast()
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
        <ChakraProvider theme={theme}>
            <React.StrictMode>
                <BrowserRouter>
                    <App/>
                    <ToastContainer/>
                </BrowserRouter>
            </React.StrictMode>
        </ChakraProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
