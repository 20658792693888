import LoginPageTemplate from "../templates/NoAuthTemplate";

const PrivacyPolicy = () => {

    return (
        <LoginPageTemplate>

            <section className="bg-white dark:bg-gray-900 pt-20 w-5/12 mx-auto container">
                <div className={"flex flex-col space-y-2"}>
                    <h1 className={"text-3xl text-white"}>Privacy Policy</h1>
                    <p>Last Updated: 2023-05-25</p>
                    <p>
                        At CeylonAI, we are committed to protecting your privacy and ensuring the security of your
                        personal
                        information. This privacy policy outlines how we collect, use, and protect the information you
                        provide to us when accessing and using our AI app creation platform.
                    </p>
                    <h2 className={"text-2xl text-white"}>1. Information Collection</h2>
                    <h3 className={"text-xl text-white"}>1.1 Personal Information</h3>
                    <p>
                        We may collect personal information, such as your name and email address, when you sign up for a
                        CeylonAI account or participate in our free trial. We will only collect the necessary
                        information
                        required to provide our services and improve your user experience.
                    </p>
                    <h3 className={"text-2xl text-white"}>1.2 Usage Information</h3>
                    <p>
                        We may collect non-personal information about your use of our platform, including your IP
                        address,
                        device information, browser type, and usage statistics. This information is used to analyze
                        trends,
                        administer the platform, and enhance our services.
                    </p>
                    <h2 className={"text-2xl text-white"}>2. Information Use</h2>
                    <h3 className={"text-xl text-white"}>2.1 Providing Services</h3>
                    <p>
                        We use the information we collect to provide and personalize our AI app creation platform,
                        including
                        account management, customer support, and communication related to your use of the platform.
                    </p>
                    <h3 className={"text-xl text-white"}>2.2 Improving Our Platform</h3>
                    <p>
                        We may use aggregated and anonymized data for analytical purposes to improve the functionality,
                        performance, and user experience of our platform.
                    </p>
                    <h3 className={"text-xl text-white"}>2.3 Communication</h3>
                    <p>
                        With your consent, we may send you promotional emails or newsletters about new features,
                        updates, or
                        other relevant information. You can opt out of these communications at any time by following the
                        instructions provided in the emails.
                    </p>
                    <h2 className={"text-2xl text-white"}>3. Information Sharing</h2>
                    <h3 className={"text-xl text-white"}>3.1 Third-Party Service Providers</h3>
                    <p>
                        We may share your information with trusted third-party service providers who assist us in
                        delivering
                        our services, such as hosting providers, customer support tools, and analytics services. These
                        providers are obligated to protect your information and only use it for the purposes specified
                        by
                        us.
                    </p>
                    <h3 className={"text-xl text-white"}>3.2 Legal Compliance</h3>
                    <p>
                        We may disclose your information to comply with applicable laws, regulations, legal processes,
                        or
                        enforceable governmental requests. We will also do so to protect our rights, privacy, safety, or
                        the
                        rights, privacy, or safety of our users or others.
                    </p>
                    <h2>4. Data Security</h2>
                    <p>
                        We implement industry-standard security measures to protect your personal information from
                        unauthorized access, loss, misuse, or alteration. However, no method of transmission over the
                        internet or electronic storage is 100% secure. Therefore, while we strive to protect your
                        information, we cannot guarantee its absolute security.
                    </p>
                    <h2 className={"text-2xl text-white"}>5. Your Choices</h2>
                    <p>
                        You have the right to access, update, correct, or delete your personal information. You may also
                        choose to unsubscribe from our promotional communications by following the instructions provided
                        in
                        the emails.
                    </p>
                    <p>
                        You have the right to access, update, correct, or delete your personal information. You may also
                        choose to unsubscribe from our promotional communications by following the instructions provided
                        in
                        the emails.
                    </p>
                    <h2 className={"text-2xl text-white"}>6. Children's Privacy</h2>
                    <p>
                        Our platform is not intended for individuals under the age of 18. We do not knowingly collect
                        personal information from children. If we become aware that we have inadvertently collected
                        personal
                        information from a child, we will take reasonable steps to delete it.
                    </p>
                    <h2 className={"text-2xl text-white"}>7. Changes to this Policy</h2>
                    <p>
                        We may update this privacy policy from time to time to reflect changes in our practices or legal
                        requirements. We encourage you to review this policy periodically for the latest information on
                        our
                        privacy practices.
                    </p>
                    <h2 className={"text-2xl text-white"}>8. Contact Us</h2>
                    <p>
                        If you have any questions, concerns, or requests regarding this privacy policy or the way we
                        handle
                        your personal information, please contact us at [contact email].
                    </p>
                    <p>
                        By using our AI app creation platform, you consent to the collection, use, and disclosure of
                        information in accordance with this privacy policy.
                    </p>
                    <p>© 2023-2024 SYIGEN PRIVATE LIMITED. All rights reserved.</p>
                </div>
            </section>
        </LoginPageTemplate>
    )
}

export default PrivacyPolicy
