import {ReactNode, useState} from "react"
import Header from "./Header";

interface IProps {
    children: ReactNode
}

const DashboardPageTemplate = ({children}: IProps) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <div className="flex justify-center h-screen dark:bg-gray-900">
            {/* Content area */}
            <div className="flex flex-col flex-1 container">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {children}
                    </div>
                </main>
            </div>
        </div>)
}

export default DashboardPageTemplate