import {useStores} from "../../../context/StoreContext";

const SaveButton = () => {
    const {designerStore, notificationStore} = useStores();
    return (<>
        <button
            className={"btn btn-xs"}
            onClick={() => {
                designerStore.save().catch(console.error);
                let json = JSON.stringify(designerStore.data);
                let blob = new Blob([json], {type: "application/json"});
                let url = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.download = "workflow.json";
                a.href = url;
                a.click();
                a.remove();
                notificationStore.addSuccess("Project saved");
            }}>
            Save
        </button>
    </>);
}

export default SaveButton;