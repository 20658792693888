import {observer} from "mobx-react";
import EditorPageTemplate from "./templates/DashboardPageTemplate";
import {AddIcon, EditIcon} from "@chakra-ui/icons";
import {IconButton} from "@chakra-ui/react";
import {NavLink} from "react-router-dom";
import {RoutePaths} from "../routes/RoutePaths";
import {useStores} from "../context/StoreContext";
import {Stage} from "../components/ToolBar/ToolBarData";


const ProjectsDashboard = observer(() => {
        const {projectStore} = useStores();
        return (
            <EditorPageTemplate>

                <div className="grid grid-cols-6 gap-6">
                    <NavLink to={RoutePaths.CREATE_PROJECT}
                             className={`flex rounded-lg drop-shadow-xl drop-shadow-purple-900 bg-purple-100 dark:bg-purple-700 
                    flex flex-col justify-center items-center p-16`}>
                        <AddIcon fontSize={96} className={"dark:text-white text-purple-600"}/>
                    </NavLink>
                    {projectStore.projects.map((p) => {
                        return (
                            <>
                                <NavLink key={p.id} to={`${RoutePaths.PROJECT}${p.id}/${Stage.DESIGNER}`}
                                         className={`flex rounded-lg drop-shadow-xl drop-shadow-purple-900 border border-purple-100 
                    dark:border-purple-700 flex flex-col justify-center items-center`}>
                                    <div className={"absolute top-0 right-0"}>
                                        <IconButton
                                            variant='outline'
                                            colorScheme='purple'
                                            aria-label={"Edit"}
                                            textColor={"text-white"}
                                            size={"sm"}
                                            icon={<EditIcon color={"white"}/>}/>
                                    </div>
                                    <h2 className={"text-white font-semibold text-xl"}>{
                                        p.name
                                    }</h2>
                                </NavLink>
                            </>
                        )
                    })}

                </div>
            </EditorPageTemplate>
        );
    })
;

export default ProjectsDashboard;
