import {EdgeData, NodeData} from "../data/designer";
import Graph from "graphology";
import {NodeDataBuilder} from "Elements/Utils";


export const ComputeGraph = async (graph: Graph) => {
    const bottomNodes = graph.filterNodes(node => graph.outDegree(node) === 0);

    const processNodes = async (currentNode: string): Promise<any> => {
        const node: NodeData = graph.getNodeAttributes(currentNode) as NodeData;
        const {data} = node;
        let dataObj = NodeDataBuilder(data);

        const upstreamNodes = graph.inboundNeighbors(currentNode);
        let upstreamResults = [];

        for (const upstreamNode of upstreamNodes) {
            const upstreamResult = await processNodes(upstreamNode);
            upstreamResults.push(upstreamResult);
        }

        let result = null;
        let outputId = node.data.outputId;
        if (dataObj && dataObj.process) {
            const combinedObject = upstreamResults.reduce((acc, cur) => {
                return {...acc, ...cur};
            }, {});
            result = await dataObj.process(combinedObject);
            graph.replaceNodeAttributes(currentNode, {
                ...node,
                data: {
                    ...dataObj,
                }
            })

            outputId = dataObj.outputId;
        }

        return {
            [outputId]: result,
        };
    }

    const finalResults = [];
    for (const bottomNode of bottomNodes) {
        const result = await processNodes(bottomNode);
        finalResults.push(result);

    }
    return {finalResults, graph};
}

export const hasCircularDependency = (nodes: NodeData[], edges: EdgeData[]): boolean => {
    const adjacencyList = new Map<string, string[]>();

    for (const edge of edges) {
        if (!adjacencyList.has(edge.source)) {
            adjacencyList.set(edge.source, []);
        }
        adjacencyList.get(edge.source)!.push(edge.target);
    }

    const visited = new Set<string>();
    const recursionStack = new Set<string>();

    function isCyclic(nodeId: string): boolean {
        visited.add(nodeId);
        recursionStack.add(nodeId);

        const neighbors = adjacencyList.get(nodeId);
        if (neighbors) {
            for (const neighbor of neighbors) {
                if (!visited.has(neighbor) && isCyclic(neighbor)) {
                    return true;
                } else if (recursionStack.has(neighbor)) {
                    return true;
                }
            }
        }

        recursionStack.delete(nodeId);
        return false;
    }

    for (const node of nodes) {
        if (!visited.has(node.id) && isCyclic(node.id)) {
            return true;
        }
    }

    return false;
}