import {Registry} from "./Registry";

export const NodeDataBuilder = (data: any) => {
    let dataObj = null;
    for (const em of Registry) {
        if (em.id === data.dataTypeId) {
            dataObj = new (em.dataClass as any)(data);
            break;
        }
    }
    return dataObj;
}

export const InitializeNodeData = (type: any) => {
    let data = null;

    for (const em of Registry) {
        if (em.id === type) {
            data = (em.dataClass as any).default();
            break;
        }
    }
    return data;
}