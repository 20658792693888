import {HiUpload} from "react-icons/hi";
import {Element} from "../Element";
import {DataProcess} from "context/nodeEngine/Data";
import {NodeProps} from "reactflow";
import NodeViewTemplate from "../NodeViewTemplate";
import {Code, Input} from "@chakra-ui/react";
import {OutputHandler} from "../Handlers";
import {useStores} from "../../context/StoreContext";
import {DataVariableData} from "./InputVariableElement";

const DATA_TYPE = "FileUpload";

class FileUploadDataClass implements DataProcess {
    id = "file"
    dataTypeId = DATA_TYPE;
    outputId: string = "";
    uploadFile: any;

    constructor(partial: Partial<FileUploadDataClass>) {
        Object.assign(this, partial);
    }

    process(inputs: { [p: string]: any }): any {
    }


    static default(): FileUploadDataClass {
        return new FileUploadDataClass({});
    }

}

const FileUploadViewComponent = (props: NodeProps<FileUploadDataClass>) => {
    const {data, id} = props;
    const {designerStore} = useStores();
    const updateValues = ({value}: { value?: any }) => {
        const newData = new FileUploadDataClass({
            ...data,
            uploadFile: value || data.uploadFile,
        });
        designerStore.addNode({
            id: id,
            data: newData,
            nodeType: DATA_TYPE,
        });
    }
    return <NodeViewTemplate>
        <span className={"text-white px-4"}>File Upload</span>
        <div className={"custom-node__select  px-4"}>
            <Code className={"w-full"}>
            </Code>
        </div>
        <div className={""}>
            <OutputHandler id={data.id!}>
                <Input
                    placeholder="Select Date and Time"
                    size="md"
                    type="file"
                    onChange={(e) => {
                        updateValues({value: e.target.value})
                    }}
                />
            </OutputHandler>
        </div>
    </NodeViewTemplate>
}

export const FileUploadElement = new Element(
    DATA_TYPE,
    "File Upload",
    DATA_TYPE,
    <HiUpload/>,
    FileUploadViewComponent,
    FileUploadDataClass,
)
