import axios, {Axios, AxiosRequestConfig} from "axios";

/**
 * Based on https://stackoverflow.com/a/52657929
 * @param conditionFunction
 * @returns {Promise<any>}
 */
function until(conditionFunction: () => any): Promise<any> {
    const poll = (resolve: any): any => {
        if (conditionFunction()) {
            resolve();
        } else {
            setTimeout(() => poll(resolve), 100);
        }
    };

    return new Promise(poll);
}

const refreshAccessToken = async () => {
    const http = axios.create({
        baseURL: HttpService.getBaseUrl(),
    } as AxiosRequestConfig);
    const storedToken = JSON.parse(localStorage.getItem("token") || "");
    const body = {
        refresh: storedToken.refresh,
    };
    const response = await http.post('api/token/refresh/', body)
    const {access} = response.data;
    storedToken.access = access;
    localStorage.setItem("token", JSON.stringify(storedToken));
}

export class HttpService {

    static instance: Axios | null = null;

    // static refreshTokenInProgress = false;

    static http(): Axios {
        if (HttpService.instance === null) {
            HttpService.instance = HttpService.createInstance();
        }
        return HttpService.instance;
    }

    static getBaseUrl() {
        console.log(process.env.REACT_APP_MAIN_API);
        return process.env.REACT_APP_MAIN_API;
    }

    static createInstance(): Axios {
        //     return axios.create({
        //         insecureHTTPParser: true,
        //         baseURL: HttpService.getBaseUrl(),
        //     } as AxiosRequestConfig);
        // }

        const config = {
            baseURL: HttpService.getBaseUrl(),
        } as AxiosRequestConfig;

        const instance = axios.create(config);


        // // Interceptor to add authorization header
        // instance.interceptors.request.use(async function (config) {
        //     // if refresh token is in progress wait until it completes
        //
        //     const tokenString = localStorage.getItem("token") || null;
        //     if (tokenString) {
        //         const token = JSON.parse(tokenString);
        //         config.headers!!["Authorization"] = `Bearer ${token.access}`;
        //     }
        //     return config;
        // });
        //
        // instance.interceptors.response.use(function (response) {
        //     return response;
        // }, async function (error) {
        //     console.log(error);
        //     const {config, response: {status, data: {code}}} = error;
        //     const originalRequest = config;
        //     console.log(status, code);
        //     if (status !== 401 || code !== "token_not_valid") {
        //         return Promise.reject(error);
        //     }
        //
        //
        //     return Promise.reject(error);
        // });

        return instance;
    }
}

