import {useState} from "react";
import {Flex} from "@chakra-ui/react";
import Divider from "./components/Divider";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Messages from "./components/Messages";

export const Chat = () => {

    const [messages, setMessages] = useState([
        {from: "computer", text: "Hi, My Name is HoneyChat"},
        {from: "me", text: "Hey there"},
        {from: "me", text: "Myself Ferin Patel"},
        {
            from: "computer",
            text:
                "Nice to meet you. You can send me message and i'll reply you with same message."
        }
    ]);
    const [inputMessage, setInputMessage] = useState("");

    const handleSendMessage = () => {
        if (!inputMessage.trim().length) {
            return;
        }
        const data = inputMessage;

        setMessages((old) => [...old, {from: "me", text: data}]);
        setInputMessage("");

        setTimeout(() => {
            setMessages((old) => [...old, {from: "computer", text: data}]);
        }, 1000);
    };

    return (
        <>
            <Flex w="100%" h="100%" justify="center" align="center">
                <Flex w={["100%", "100%", "40%"]} h="90%" flexDir="column">
                    <Header/>
                    <Divider/>
                    <div style={{
                        height: "600px",
                    }}>
                        <Messages messages={messages}/>
                    </div>
                    <Divider/>
                    <Footer
                        inputMessage={inputMessage}
                        setInputMessage={setInputMessage}
                        handleSendMessage={handleSendMessage}
                    />
                </Flex>
            </Flex>
        </>
    );
}