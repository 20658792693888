import LoginPageTemplate from "../templates/NoAuthTemplate";

const RefundPolicy = () => {
    return (
        <LoginPageTemplate>

            <section className="bg-white dark:bg-gray-900 pt-20 w-5/12 mx-auto container">
                <div className={"flex flex-col space-y-2"}>
                    <h1 className={"text-3xl text-white"}>Refund Policy</h1>
                    <p>
                        We want you to be satisfied with your purchase of our services. If you are not entirely
                        satisfied,
                        we offer a refund within a certain period of time, subject to the following terms and
                        conditions:
                    </p>
                    <p>
                        1. Eligibility for Refund: You may request a refund within 7 days from the date
                        of
                        purchase.
                    </p>
                    <p>
                        2. Refund Method: We will initiate the refund to the same payment method used for the original
                        purchase. Please allow 14 days for the refund to be processed and reflect in your
                        account.
                    </p>
                    <p>
                        3. Refund Exceptions: The following circumstances may affect your eligibility for a refund:
                        <ul>
                            <li>Services used or accessed beyond the refund eligibility period.</li>
                            <li>Violation of our terms of service or misuse of our platform.</li>
                            <li>Refunds requested for reasons not related to the performance or functionality of our
                                services.
                            </li>
                        </ul>
                    </p>
                    <p>
                        4. How to Request a Refund: To request a refund, please contact our customer support team at
                        info@ceylon.ai. Provide your name, email address used for the purchase, and any relevant
                        details
                        regarding your refund request. We will review your request and respond to you in a timely
                        manner.
                    </p>
                    <p>
                        Please note that our refund policy may be subject to change without prior notice. We encourage
                        you
                        to review the refund policy periodically for any updates or modifications.
                    </p>
                </div>
            </section>
        </LoginPageTemplate>
    );
}

export default RefundPolicy