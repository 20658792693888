import RootStore from "./RootStore";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import UserService from "context/service/UserService";
import ProjectUser from "context/models/ProjectUser";

export default class UserStore {

    @observable
    public authUser?: any;

    @observable
    public user?: ProjectUser;

    @observable
    public users: ProjectUser[] = [];


    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
    }

    @action
    async createUser(user: any) {
        this.user = user;
        await UserService.createUser(user);
    }

    async selectPlan(selectPlan: any) {
        // await UserService.selectPlan(selectPlan, this.user)
    }

    @action
    async getUsers() {
        this.users = await UserService.getUsers();
    }


    async suspendUser(rowData: any) {
        // await UserService.suspendUser(rowData);
        // await this.getUsers();
        //TODO user suspended
    }

    @action
    logout() {
        this.user = undefined;
        localStorage.clear();
    }

    @action
    async setUser(user: any) {
        this.authUser = user;
        UserService.getLoggedUser().then(projectUser => {
            runInAction(() => {
                this.user = projectUser;
            });
        });
    }
}