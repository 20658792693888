import Chat from "../../../UserApp/Chat";

interface ChatUIProps {
    projectUrl: string;
}


const ChatUI = ({projectUrl}: ChatUIProps) => {
    return (
        <div style={{position: "relative", height: "500px"}}>
            <Chat/>
        </div>
    )
}

export default ChatUI;