import {Timestamp} from "firebase/firestore"

export enum UserRole {
    ADMIN = "admin",
    USER = "user",
}

export default class ProjectUser {

    createdAt?: Timestamp;
    displayName?: string;
    email?: string;
    photoURL?: string;
    role?: string;
    subscriptionPlan?: string;
    uid?: string;
    username?: string;

    constructor(partials: Partial<ProjectUser>) {
        Object.assign(this, partials);
    }
}