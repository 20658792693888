export enum DataType {
    INT = 'INT',
    FLOAT = 'FLOAT',
    TEXT = 'TEXT',
    DATE = 'DATE',
    TIME = 'TIME',
    DATETIME = 'DATETIME',
    BOOLEAN = 'BOOLEAN',
    OBJECT = 'OBJECT',
}
