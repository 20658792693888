import {useLogout} from "../../context/hooks/logout";
import {IconButton} from "@chakra-ui/react";
import {HiLockClosed} from "react-icons/hi2";
import React from "react";


const LogoutButton = () => {

    const {logout} = useLogout();

    return (
        <>
            <IconButton aria-label={"Logout"}
                        onClick={logout}
                        icon={<HiLockClosed/>}/>
        </>
    )
}

export default LogoutButton