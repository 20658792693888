import {Card, CardBody, HStack, IconButton} from "@chakra-ui/react";
import {NodeToolbar, Position} from "reactflow";
import {HiPencil, HiPlay, HiTrash, HiPause} from "react-icons/hi2";
import {useState} from "react";


const NodeViewTemplate = ({children, selected}: any) => {
    const [isActive, setIsActive] = useState(false)
    return (<>
        <NodeToolbar isVisible={selected} position={Position.Top || Position.Left}>
            <HStack spacing={"4px"} bg={"teal"} width={"full"} p={1} borderRadius={"lg"}>
                {isActive ? <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    onClick={() => setIsActive(false)}
                    icon={<HiPause/>}
                /> : <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    onClick={() => setIsActive(true)}
                    icon={<HiPlay/>}
                />}
                <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    icon={<HiPencil/>}
                />
                <IconButton
                    colorScheme='blue'
                    aria-label='Search database'
                    icon={<HiTrash/>}
                />
            </HStack>
        </NodeToolbar>
        <Card className={"p-0"}>
            <CardBody className={"m-0"} padding={"0"}>
                <div className={"flex flex-col space-y-4 w-80 py-4"}>
                    {children}
                </div>
            </CardBody>
        </Card>
    </>)
}
export default NodeViewTemplate