import React, {useEffect, useState} from 'react';
import {StoreProvider, useStores} from "../context/StoreContext";
import RootStore from "context/store/RootStore";
import AuthRoutes from "../routes/AuthRoutes";
import {observer} from "mobx-react";
import {useToast} from '@chakra-ui/react'
import {AlertStatus} from "@chakra-ui/alert";
import {auth} from "../firebaseConfig";

const NotificationView = observer(() => {
    const toast = useToast()
    const {notificationStore} = useStores();
    useEffect(() => {
        if (notificationStore.messageQueue.length > 0) {
            for (const message of notificationStore.popMessages()) {
                let severity = "success";
                switch (message.severity) {
                    case "INFO":
                        severity = "info";

                }
                toast({
                    title: message.summary,
                    description: message.detail,
                    colorScheme: "blue",
                    status: severity as AlertStatus,
                    duration: 2000,
                    isClosable: true,
                    position: "bottom-right"
                })
            }
        }
    }, [toast, notificationStore.messageQueue]);

    return (<>

    </>);
});
const AuthStateChecker = () => {
    const {userStore} = useStores();
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                userStore.setUser(user);
            }
        });
    }, [])
    return <></>
}

function App() {
    const [rootStore] = useState(new RootStore());
    return (
        <>
            <StoreProvider store={rootStore}>
                <AuthStateChecker/>
                <NotificationView/>
                <AuthRoutes/>
            </StoreProvider>
        </>
    );
}

export default App;
