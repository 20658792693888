import {Handle, Position} from "reactflow";
import {ReactElement} from "react";

interface InputHandlerProps {
    children: ReactElement;
    id: string;
    onConnect?: (params: any) => void;
}

export const InputHandler = ({id, children, onConnect}: InputHandlerProps) => {
    return <div className={"custom-node__select flex flex-row justify-between px-4"}>
        {children}
        <Handle
            type={"target"}
            id={id}
            position={Position.Left}
            style={{
                background: '#090903',
                height: 8,
                width: 8,
                borderRadius: 5,
                border: '1px solid',
                borderColor: '#0d74da'
            }}
            onConnect={(params) => {
                if (onConnect) onConnect(params)
            }}
            isConnectable={true}
        />
    </div>
}

interface OutputHandlerProps {
    id: string;
    children: ReactElement;
    onConnect?: (params: any) => void;
}

export const OutputHandler = ({id, onConnect, children}: OutputHandlerProps) => {
    return <div className={"custom-node__select flex flex-row justify-between px-4"}>

        {children}

        <Handle
            type={"source"}
            id={`${id}_output`}
            position={Position.Right}
            style={{
                background: '#090903',
                height: 8,
                width: 8,
                borderRadius: 5,
                border: '1px solid',
                borderColor: '#dab00d'
            }}
            onConnect={(params) => {
                if (onConnect) onConnect(params)
            }}
            isConnectable={true}
        />
    </div>
}