import {ReactNode, useState} from "react"
import ToolBar from "../../../components/ToolBar";
import {Stage} from "../../../components/ToolBar/ToolBarData";
import {useNavigate, useParams} from "react-router-dom";
import {RoutePaths} from "../../../routes/RoutePaths";

interface IProps {
    children: (stage: Stage) => ReactNode
}

const EditorPageTemplate = ({children}: IProps) => {
    const {id} = useParams();
    const navigator = useNavigate();
    const [stage, setStage] = useState(Stage.DESIGNER) // ['designer', 'testbench', 'launchpad'
    return (
        <div className="flex justify-center h-screen w-full dark:bg-gray-900">
            {/* Content area */}
            <div className="flex flex-col flex-1">
                <ToolBar onChange={(stage) => {
                    setStage(stage);
                    navigator(`${RoutePaths.PROJECT}${id}/${stage}`)
                }} selectedStage={stage}/>
                {children(stage)}
            </div>
        </div>)
}

export default EditorPageTemplate