import {signOut} from "firebase/auth";
import {auth} from "../../firebaseConfig";
import {useStores} from "../StoreContext";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../routes/RoutePaths";

export const useLogout = () => {
    const {userStore} = useStores();
    const navigate = useNavigate();
    const logout = async () => {
        try {
            await signOut(auth);
            userStore.logout();
            navigate(RoutePaths.HOME);
        } catch (error: any) {
            console.log(error.message);
        }
    };
    return {logout}
}