import {useStores} from "../../../context/StoreContext";

const GenerateButton = () => {
    const {designerStore} = useStores();
    return (<>
        <button
            className={"btn btn-xs"}
            onClick={() => {
                designerStore.generateCode().then((code) => {
                    console.log(code);
                }).catch(console.error);
            }}>
            Generate Code
        </button>
    </>);
}

export default GenerateButton;