import {ReactNode} from "react";

interface IDraggableProps {
    children: ReactNode;
    onDragStart: (event: any) => void;
}

const Draggable = ({children, onDragStart}: IDraggableProps) => {
    return (
        <div className={"dndnode"} onDragStart={onDragStart} draggable>
            {children}
        </div>
    )
}
export default Draggable;