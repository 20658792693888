import {Edge, EdgeTypes, Node, NodeTypes} from "reactflow";
import NodeConnectorEdge from "../../components/Edges/FunctonInOutEdge";
import {Registry} from "Elements/Registry";

export type NodeData = Node;
export type EdgeData = Edge;

export const ElementRegistry = [
    ...Registry
]

export enum EdgeTypeNames {
    NODE_IN_OUT = "finout",
}

export const nodeTypes = () => {
    const _nodeTypes: any = {}
    for (const em of ElementRegistry) {
        _nodeTypes[em.id as string] = em.viewComponent;
    }
    return {
        ..._nodeTypes
    } as NodeTypes;
};
export const edgeTypes: EdgeTypes = {
    [`${EdgeTypeNames.NODE_IN_OUT}`]: NodeConnectorEdge,
};

export enum NodeEdgeDirection {
    INPUT = "input",
    OUTPUT = "output",
}