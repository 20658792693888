import {useEffect, useState} from 'react';
import {useStores} from "../../context/StoreContext";
import {observer} from "mobx-react";
import EditorPageTemplate from ".././templates/EditorPageTemplate";
import {useParams} from "react-router-dom";
import {Stage} from "../../components/ToolBar/ToolBarData";
import ProjectEditorView from "./EditorView";
import TestBench from "./TestBench";
import {Project} from "../../context/models/Project";
import LaunchPad from "./LaunchPad";


function ProjectEditor() {
    const {id,tab} = useParams();
    const {projectStore, userStore} = useStores();
    const [project, setProject] = useState<Project>()

    useEffect(() => {
        if (id && userStore.user) {
            projectStore.loadProject(id).catch(console.error);
        }
    }, [id, projectStore, userStore, userStore.user])

    useEffect(() => {
        if (projectStore.project) {
            setProject(projectStore.project)
        }
    }, [projectStore.project])

    return (
        <>
            <EditorPageTemplate>
                {((stage) => {
                    return (
                        <>
                            {!project && <div className={"text-white"}>Loading...</div>}
                            {project && <>
                                {stage === Stage.DESIGNER && <ProjectEditorView project={project}/>}
                                {stage === Stage.TESTBENCH && <TestBench project={project}/>}
                                {stage === Stage.LAUNCHPAD && <LaunchPad project={project}/>}
                            </>}
                        </>
                    )
                })}
            </EditorPageTemplate>
        </>
    );
}

export default observer(ProjectEditor);