import React, {useEffect, useState} from "react";
import {Box, Button, Flex, Text} from "@chakra-ui/react";
import {HiListBullet, HiOutlinePencil, HiOutlinePlay, HiOutlineRocketLaunch} from "react-icons/hi2";
import {NavLink} from "react-router-dom";
import {RoutePaths} from "../../routes/RoutePaths";
import {observer} from "mobx-react";
import {useStores} from "../../context/StoreContext";
import {Stage} from "./ToolBarData";


const ProjectDetailView = observer(() => {
    const {projectStore} = useStores();
    return (<div className="flex items-center justify-between h-16 -mb-px">

        <Text fontSize={"lg"} fontWeight={"bold"}>{projectStore.project?.name}</Text>

    </div>);
})

interface IProps {
    selectedStage: Stage
    onChange: (stage: Stage) => void
}

const ToolBar = ({selectedStage, onChange}: IProps) => {
    const [stage, setStage] = useState(selectedStage) // ['designer', 'testbench', 'launchpad'
    useEffect(() => {
        onChange(stage)
    }, [stage])
    return (<header
            className="sticky top-0 bg-white border-b border-slate-200 z-30  dark:bg-gray-900 dark:border-slate-800">
            <div className="px-4">
                <div className="flex items-center justify-between h-16 -mb-px">
                    <Flex gap='2'>
                        <NavLink to={RoutePaths.PROJECTS}>
                            <Box p={"4"} border={"blue"} borderWidth={"2px"}>
                                <HiListBullet/>
                            </Box>
                        </NavLink>
                    </Flex>

                    <Flex>
                        <ProjectDetailView/>
                    </Flex>


                    <Flex gap='2'>
                        <Button
                            onClick={() => setStage(Stage.DESIGNER)}
                            rightIcon={<HiOutlinePencil/>} colorScheme='blue' variant='outline'
                            borderWidth={"2px"}
                            borderColor={stage === Stage.DESIGNER ? "blue.800" : "transparent"}>
                            Designer
                        </Button>
                        <Button
                            onClick={() => setStage(Stage.TESTBENCH)}
                            borderWidth={"2px"}
                            rightIcon={<HiOutlinePlay/>} colorScheme='blue' variant='outline'
                            borderColor={stage === Stage.TESTBENCH ? "blue.800" : "transparent"}>
                            Test Bench
                        </Button>
                        <Button
                            onClick={() => setStage(Stage.LAUNCHPAD)}
                            borderWidth={"2px"}
                            rightIcon={<HiOutlineRocketLaunch/>} colorScheme='blue' variant='outline'
                            borderColor={stage === Stage.LAUNCHPAD ? "blue.800" : "transparent"}>
                            Launch Pad
                        </Button>
                    </Flex>

                </div>
            </div>
        </header>
    )
}

export default ToolBar