import ProjectUser from "./ProjectUser";

export class Project {
    name?: string;
    owner?: ProjectUser;
    id?: string;

    nodes?: any[];
    edges?: any[];
    nextNodeId: number=0;

    constructor(partials: Partial<Project>) {
        Object.assign(this, partials);
        if (this.owner) {
            this.owner = new ProjectUser(this.owner);
        }
    }
}