import React, {useEffect, useState} from 'react';
import {EdgeProps, getBezierPath} from 'reactflow';
import {useStores} from "../../../context/StoreContext";


const foreignObjectSize = 40;


const RemoveButton = ({id}: { id: any }) => {
    const {designerStore} = useStores();
    const onEdgeClick = (evt: any, id: any) => {
        evt.stopPropagation();
        designerStore.removeEdge(id);
        console.log(id);
    };
    return (
        <>
            <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
                ×
            </button>
        </>
    )
}

const FunctionConnectorEdge = ({
                                   id,
                                   sourceX,
                                   sourceY,
                                   targetX,
                                   targetY,
                                   sourcePosition,
                                   targetPosition,
                                   style = {},
                                   markerEnd,
                               }: EdgeProps) => {
    const [edgePath, setEdgePath] = useState<any>();
    const [labelX, setLabelX] = useState<any>();
    const [labelY, setLabelY] = useState<any>();

    useEffect(() => {
        const [edgePath, labelX, labelY] = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        })
        setEdgePath(edgePath);
        setLabelX(labelX);
        setLabelY(labelY);
    }, [sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition])

    return (
        <>
            <path
                id={id}
                style={style}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
            />
            <foreignObject
                width={foreignObjectSize}
                height={foreignObjectSize}
                x={labelX - foreignObjectSize / 2}
                y={labelY - foreignObjectSize / 2}
                className="edgebutton-foreignobject"
                requiredExtensions="http://www.w3.org/1999/xhtml"
            >
                <div>
                    <RemoveButton id={id}/>
                </div>
            </foreignObject>
        </>
    );
}

export default FunctionConnectorEdge;