import {useState} from "react";
import {useStores} from "../../context/StoreContext";
import {IconButton} from "@chakra-ui/react";
import {TbClipboardCopy} from "react-icons/tb";

const CopyToClipboard = ({text}: { text: string }) => {
    const [isCopied, setIsCopied] = useState(false);
    const {notificationStore} = useStores();

    // This is the function we wrote earlier
    async function copyTextToClipboard(text: string) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(text)
            .then(() => {
                // If successful, update the isCopied state value
                notificationStore.addSuccess("Copied to clipboard");
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <IconButton
                onClick={handleCopyClick}
                icon={<TbClipboardCopy/>} aria-label={"copy"} size={"xs"}/>
        </>
    )

}

export default CopyToClipboard;