import {Project} from "../../context/models/Project";
import {Box, ButtonGroup, CloseButton, Flex, Text, VStack} from "@chakra-ui/react";
import {useState} from "react";
import ChatUI from "./TestBench/Chat";
import CopyToClipboard from "../../components/CopyToClipBoard";


const TestBench = ({project}: { project: Project }) => {
    const [proectUrl, setProjectUrl] = useState<string>("http://127.0.0.1:47854/");

    return (
        <div className={"container mx-auto h-full py-4 drop-shadow-2xl"}>
            <VStack className={"bg-slate-800"} height={"full"}>
                <Box className={"w-full bg-slate-700 rounded-t-lg p-2 flex flex-grow"}>
                    <Flex alignItems='center' gap='2' width={"full"} grow={"1"}>
                        <Flex p='1' grow={"1"} pl={"2"} justify={"space-between"} display={"flex"} alignItems='baseline'
                              borderWidth={"1px"}
                              className={"bg-slate-900 flex-grow"} rounded={"lg"}>
                            <Text>
                                {proectUrl}
                            </Text>
                            <CopyToClipboard text={proectUrl}/>
                        </Flex>
                        <ButtonGroup gap='2'>
                            <CloseButton rounded={"full"} background={"slategray"} size={"sm"}/>
                        </ButtonGroup>
                    </Flex>
                </Box>
                <Box
                    width={"full"}
                    height={"full"}
                    className={"rounded-lg p-2"}>
                    <ChatUI projectUrl={proectUrl}/>
                </Box>
            </VStack>
        </div>
    );
}

export default TestBench;