import api from "../utils/api";
import {EdgeData, NodeData} from "../data/designer";

export class FuncGenService {

    static async saveProcess(data: { nodes: NodeData[], edges: EdgeData[] }) {
        const res = await api.post('/module', data);
        return res.data;
    }

    static async generateCode() {
        const res = await api.get('/module/generate');
        return res.data;
    }
}