import {useFormik} from "formik";
import {Button, FormControl, FormErrorMessage, FormLabel, Input} from "@chakra-ui/react";
import {observer} from "mobx-react";
import {useStores} from "../../../context/StoreContext";
import {animals, colors, names, uniqueNamesGenerator} from "unique-names-generator";

const ProjectAdd = () => {
    const {projectStore, userStore, notificationStore} = useStores();
    const formik = useFormik({
        initialValues: {
            name: `${uniqueNamesGenerator({
                dictionaries: [names, animals, colors],
                separator: "-"
            })}`.toLowerCase().replace(" ", "-")
        },
        onSubmit: async (values, {resetForm}) => {
            if (userStore.user) {
                await projectStore.createProject(userStore.user, values.name)
                resetForm();
                notificationStore.addSuccess("Project created");
            } else {
                notificationStore.addError("User not logged")
            }
        }
    })

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormControl isInvalid={(formik.errors.name !== null) || false}>
                    <FormLabel>Project name</FormLabel>
                    <Input name={"name"}
                           textColor={"white"}
                           value={formik.values.name} onChange={formik.handleChange}
                           placeholder='name'/>
                    <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                </FormControl>
                <Button
                    mt={4}
                    colorScheme="gray"
                    isLoading={formik.isSubmitting}
                    type="submit">
                    Submit
                </Button>
            </form>
        </>
    )
}

export default observer(ProjectAdd)