import {makeAutoObservable} from "mobx";
import DesignerStore from "./DesignerStore";
import {NotificationStore} from "./NotificationStore";
import UserStore from "./UserStore";
import {ProjectStore} from "./ProjectStore";

class RootStore {

    notificationStore: NotificationStore;
    designerStore: DesignerStore;
    userStore: UserStore
    projectStore: ProjectStore;

    constructor() {
        this.notificationStore = new NotificationStore(this);
        this.userStore = new UserStore(this);
        this.projectStore = new ProjectStore(this);
        this.designerStore = new DesignerStore(this);
        makeAutoObservable(this);
    }

}

export default RootStore;