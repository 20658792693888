import {db} from "../../firebaseConfig"
import {collection, doc, getDocs, query, serverTimestamp, setDoc, where} from "firebase/firestore";
import {Project} from "../models/Project";
import ProjectUser from "../models/ProjectUser";


export default class ProjectService {

    static createProject = async (project: Project) => {
        const {name, owner, id,nextNodeId} = project;
        const projectRef = doc(db, `projects/${owner?.uid}`);
        const colProjects = collection(projectRef, `projects`);
        const docRef = doc(colProjects, `${id}`);
        await setDoc(docRef, {
            id,
            name,
            owner: owner?.uid,
            status: "default",
            nextNodeId,
            createdAt: serverTimestamp(),
        });
    }
    static getProjects = async (user: ProjectUser) => {
        const projects: Project[] = [];
        const q = query(collection(db, `projects/${user.uid}/projects`));
        const {docs} = await getDocs(q);
        docs.forEach(doc => {
            const documentData = doc.data();
            const documentObject = Object.assign({}, documentData);
            projects.push(new Project(documentObject));
        });
        return projects;
    }

    static async updateProject(project: Project, data: any) {
        const {id, owner,nextNodeId} = project;
        const projectRef = doc(db, `projects/${owner?.uid}`);
        const colProjects = collection(projectRef, `projects`);
        const docRef = doc(colProjects, `${id}`);
        console.log("updateProject4444", project, data);
        await setDoc(docRef, {
            nextNodeId,
        });
        console.log("updateProject", project, data);
    }

    static getProject = async (id: string, owner: ProjectUser) => {
        const projectRef = doc(db, `projects/${owner?.uid}`);
        const colProjects = collection(projectRef, `projects`);
        const q = query(colProjects, where("id", "==", id));
        const {docs} = await getDocs(q);
        if (docs.length === 0) throw new Error("Project not found");
        const documentData = docs[0].data();
        const documentObject = Object.assign({}, documentData);
        return new Project(documentObject);
    }
}