import {auth, db} from "../../firebaseConfig"
import {collection, doc, getDocs, query, serverTimestamp, setDoc, updateDoc, where} from "firebase/firestore";
import ProjectUser from "context/models/ProjectUser";


export default class UserService {

    static selectPlan = async (subscriptionPlan: any, user: any) => {
        const docRef = doc(db, `users/${user.uid}`);
        await updateDoc(docRef, {
            substcriptionPlan: subscriptionPlan
        });
    }

    static createUser = async (user: any) => {
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const {docs} = await getDocs(q);

        if (docs.length === 0) {
            const {uid, displayName, email, photoURL, reloadUserInfo} = user;

            const docRef = doc(db, `users/${uid}`);
            await setDoc(docRef, {
                displayName,
                subscriptionPlan: "FREE",
                email,
                photoURL,
                uid,
                status: "default",
                role: "user",
                username: reloadUserInfo.screenName,
                createdAt: serverTimestamp(),

            });
        }
    };

    static getUsers = async () => {

        const snapshot = await getDocs(collection(db, "users"));
        const documents: any[] = [];
        snapshot.forEach(doc => {
            const document = doc.data();
            documents.push(document);
        })
        return documents;

    }

    static getLoggedUser = async (): Promise<ProjectUser | undefined> => {
        const user = await auth.currentUser;
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uid", "==", `${user?.uid}`));
        const snapshot = await getDocs(q);
        let projectUser: ProjectUser | undefined = undefined;
        if (snapshot.docs.length === 1) {
            const documentData = snapshot.docs[0].data();
            const documentObject = Object.assign({}, documentData);
            projectUser = new ProjectUser(documentObject);
        }
        return projectUser;
    }

    static suspendUser = async (rowData: any) => {
        const Status = rowData.status === "suspended" ? "default" : "suspended"
        const docRef = doc(db, `users/${rowData.uid}`);
        await updateDoc(docRef, {
            status: Status
        });
    }
}