import {Project} from "../../context/models/Project";

const LaunchPad = ({project}: { project: Project }) => {
    return (
        <div>
            <h1>Launch Pad</h1>
        </div>
    );
}

export default LaunchPad;