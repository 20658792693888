import {AutoDraft, BasicStorage, ChatProvider} from "@chatscope/use-chat";
import {nanoid} from "nanoid";
import {UserChatService} from "UserApp/Chat/UserChatService";
import {Chat} from "./chat";
// Storage needs to generate id for messages and groups
const messageIdGenerator = () => nanoid();
const groupIdGenerator = () => nanoid();

// Create serviceFactory
const serviceFactory = (storage: any, updateState: any) => {
    return new UserChatService(storage, updateState);
};

const chatStorage = new BasicStorage({groupIdGenerator, messageIdGenerator});
export const ChatUI = () => {
    return (
        <ChatProvider serviceFactory={serviceFactory} storage={chatStorage} config={{
            typingThrottleTime: 250,
            typingDebounceTime: 900,
            debounceTyping: true,
            autoDraft: AutoDraft.Save | AutoDraft.Restore
        }}>

            <Chat/>

        </ChatProvider>
    )
}

export default ChatUI;