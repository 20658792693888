import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Connection,
    Controls,
    DefaultEdgeOptions,
    Edge,
    Node,
    Panel,
    ReactFlowInstance,
    ReactFlowProvider
} from "reactflow";
import {observer} from "mobx-react";
import {Project} from "../../context/models/Project";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useStores} from "../../context/StoreContext";
import {EdgeTypeNames, edgeTypes, NodeData, nodeTypes} from "../../context/data/designer";
import {InitializeNodeData} from "Elements/Utils";
import NodeCreatePanel from "Elements/NodeCreatePanel";
import {Flex} from "@chakra-ui/react";
import SaveButton from "../../components/ToolBar/Buttons/SaveButton";
import RestoreButton from "../../components/ToolBar/Buttons/RestoreButton";
import GenerateButton from "../../components/ToolBar/Buttons/RunButton";


const defaultEdgeOptions: DefaultEdgeOptions = {
    animated: true,
};

const ProjectEditorView = observer(({project}: { project: Project }) => {
    const nodeTypeList = useMemo(() => nodeTypes(), []);
    const reactFlowWrapper = useRef(null);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>();
    const {designerStore} = useStores();

    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);

    useEffect(() => {
        designerStore.setProject(project);
    }, [project]);

    useEffect(() => {
        if (designerStore.nodes.length) {
            setNodes(designerStore.nodes);
        }
    }, [designerStore.nodes]);

    useEffect(() => {
        if (designerStore.edges) {
            setEdges([...designerStore.edges]);
        }
    }, [designerStore.edges]);

    const onDragOver = useCallback((event: any) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);

    const onDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            if (reactFlowInstance && reactFlowWrapper && reactFlowWrapper.current) {
                const reactFlowBounds = (reactFlowWrapper.current as any).getBoundingClientRect();
                const type = event.dataTransfer.getData('application/reactflow') as string;
                // check if the dropped element is valid
                if (typeof type === 'undefined' || !type) {
                    return;
                }

                const position = reactFlowInstance.project({
                    x: event.clientX - reactFlowBounds.left,
                    y: event.clientY - reactFlowBounds.top,
                });
                const data = InitializeNodeData(type);

                designerStore.addNode({
                    nodeType: type,
                    position,
                    data: data,
                });

            }
        },
        [reactFlowInstance, reactFlowWrapper, designerStore]
    );
    return (<>
        <ReactFlowProvider>
            <div className={"flex flex-auto border border-white border-dashed border-opacity-10"}
                 ref={reactFlowWrapper}>
                <ReactFlow
                    onInit={(reactFlowInstance) => {
                        setReactFlowInstance(reactFlowInstance);
                    }}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={(changes) => {
                        const _nodes = applyNodeChanges(changes, [...nodes]);
                        designerStore.updateNodes(_nodes as unknown as NodeData[]);
                    }}
                    onEdgesChange={(changes) => {
                        const _edges = [...edges].map((eds) => applyEdgeChanges(changes, eds as any));
                        designerStore.updateEdges(_edges as unknown as Edge[]);
                    }}
                    onConnect={
                        (connection) => {
                            const _edges = [...addEdge({
                                ...connection,
                                type: EdgeTypeNames.NODE_IN_OUT,
                            } as Connection, edges)];
                            designerStore.updateEdges(_edges as unknown as Edge[]);
                        }
                    }
                    fitView
                    defaultEdgeOptions={defaultEdgeOptions}
                    nodeTypes={nodeTypeList}
                    edgeTypes={edgeTypes}
                    onDrop={onDrop}
                    onDragOver={onDragOver}>
                    <Panel position={"top-right"}>
                        <Flex gap='2'>
                            <SaveButton/>
                            <RestoreButton/>
                            <GenerateButton/>
                        </Flex>
                    </Panel>
                    <Panel position="top-left">
                        <NodeCreatePanel/>
                    </Panel>
                    <Controls
                        className={"bg-white dark:bg-slate-400"}
                    />
                    <Background color={
                        "rgba(230,207,255,0.94)"
                    } gap={16}/>
                </ReactFlow>
            </div>
        </ReactFlowProvider>

    </>)
})

export default ProjectEditorView;