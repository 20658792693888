import {signInWithPopup} from "firebase/auth";
import {auth, provider} from "../../firebaseConfig";
import {useState} from "react";
import {useStores} from "context/StoreContext";
import firebase from "firebase/compat";


export const useLogin = () => {
    const [error, setError] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const {userStore} = useStores();

    const login = async () => {
        setIsPending(true);
        try {
            const res = await signInWithPopup(auth, provider);
            if (!res) {
                throw new Error("Could not complete signup");
            }


            const user = res.user;
            userStore.createUser(user);
            localStorage.setItem('userId', res.user.uid);
            setIsPending(false)
        } catch (error: any) {
            console.log(error);
            setError(error.message);
            setIsPending(false);
        }
    };

    return {login, error, isPending};
};