import LoginPage from "pages/LoginPage";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";

import {observer} from "mobx-react-lite";

import {useStores} from "context/StoreContext";
import ProjectsDashboard from "pages/ProjectsDashboard";
import CreateNewProject from "pages/CreateNewProject";
import {RoutePaths} from "./RoutePaths";
import ProjectEditor from "../pages/ProjectEditor";
import {ReactNode} from "react";
import PrivacyPolicy from "../pages/NoAuth/PrivacyPolicy";
import RefundPolicy from "../pages/NoAuth/RefundPolicy";

interface AuthRequiredProps {
    children: ReactNode
}

// TODO: This is a hacky way to do this. We should use a proper auth library
const AuthRequired = observer(({children}: AuthRequiredProps) => {
    const {userStore} = useStores();
    if (!userStore.authUser) {
        return <Navigate to={RoutePaths.HOME} replace={true}/>
    }
    return <>{children}</>
})


const UserRender = observer(() => {
    const {userStore} = useStores();
    if (userStore.user) {
        return <ProjectsDashboard/>
    }
    return <LoginPage/>
})

const AuthRoutes = observer(() => {
    return (
        <Routes>
            <Route path={RoutePaths.HOME} element={<UserRender/>}/>
            <Route path={RoutePaths.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
            <Route path={RoutePaths.REFUND_POLICY} element={<RefundPolicy/>}/>
            <Route path={RoutePaths.CREATE_PROJECT} element={
                <AuthRequired>
                    <CreateNewProject/>
                </AuthRequired>
            }/>
            <Route path={RoutePaths.PROJECTS} element={
                <AuthRequired>
                    <ProjectsDashboard/>
                </AuthRequired>
            }/>
            <Route path={`${RoutePaths.PROJECT}:id/:tab`} element={
                <AuthRequired>
                    <ProjectEditor/>
                </AuthRequired>
            }/>
        </Routes>
    );
})
export default AuthRoutes;