import {Box} from "@chakra-ui/react";
import {Form, Formik} from "formik";


import React from "react";
import SmartScribe from "./SmartScribe";


const Footer = ({inputMessage, setInputMessage, handleSendMessage}: any) => {
    function validateName(value: any) {
        let error
        if (!value) {
            error = 'Name is required'
        } else if (value.toLowerCase() !== 'naruto') {
            error = "Jeez! You're not a fan 😱"
        }
        return error
    }

    return (
        <Box w="100%" mt="5" borderColor={"white"} p={4} rounded={"lg"} borderWidth={"1px"}>
            <Formik
                initialValues={{name: 'Sasuke'}}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2))
                        actions.setSubmitting(false)
                    }, 1000)
                }}
            >
                {(props) => (
                    <Form>
                        <SmartScribe
                            getSuggestion={(text: string) => {
                                return "";
                            }}
                            onType={(text: string) => {
                            }}
                            placeholder={"Type Something..."}
                        />
                    </Form>
                )}
            </Formik>

            {/*<Input*/}
            {/*    placeholder="Type Something..."*/}
            {/*    border="none"*/}
            {/*    borderRadius="none"*/}
            {/*    _focus={{*/}
            {/*        border: "1px solid black",*/}
            {/*    }}*/}
            {/*    onKeyPress={(e) => {*/}
            {/*        if (e.key === "Enter") {*/}
            {/*            handleSendMessage();*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    value={inputMessage}*/}
            {/*    onChange={(e) => setInputMessage(e.target.value)}*/}
            {/*/>*/}
            {/*<Button*/}
            {/*    bg="black"*/}
            {/*    color="white"*/}
            {/*    borderRadius="none"*/}
            {/*    _hover={{*/}
            {/*        bg: "white",*/}
            {/*        color: "black",*/}
            {/*        border: "1px solid black",*/}
            {/*    }}*/}
            {/*    disabled={inputMessage.trim().length <= 0}*/}
            {/*    onClick={handleSendMessage}*/}
            {/*>*/}
            {/*    Send*/}
            {/*</Button>*/}
        </Box>
    );
};

export default Footer;
