import {ReactNode} from "react";
import Draggable from "components/Dragabble";
import {Box, HStack} from "@chakra-ui/react";

interface CreateButtonTemplateProps {
    componentType: string;
    text: string;
    icon: ReactNode;
}

const CreateButtonTemplate = ({componentType, text, icon}: CreateButtonTemplateProps) => {
    return (
        <Draggable onDragStart={(e) => {
            e.dataTransfer.setData('application/reactflow', componentType);
            e.dataTransfer.effectAllowed = 'move';
        }}>
            <HStack borderWidth={"1px"} p={4} borderRadius={"lg"} fontSize={"sm"}>
                {icon}
                <Box as={"div"} ml={"1"} className={"flex flex-col items-center h-full"}>
                    {text}
                </Box>
            </HStack>
        </Draggable>
    )
}

export default CreateButtonTemplate;