import EditorPageTemplate from "./templates/DashboardPageTemplate";
import ProjectAdd from "../components/Project/Add";
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import {useStores} from "../context/StoreContext";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {RoutePaths} from "../routes/RoutePaths";
import {observer} from "mobx-react";
import {Stage} from "../components/ToolBar/ToolBarData";


const CreateNewProject = () => {
    const navigator = useNavigate();
    const {projectStore, userStore, notificationStore} = useStores();
    useEffect(() => {
        if (projectStore.project) {
            navigator(RoutePaths.PROJECT + projectStore.project.id + "/" + Stage.DESIGNER)
        }
    }, [projectStore.project])

    return <EditorPageTemplate>
        <Tabs>
            <TabList>
                <Tab>Create New</Tab>
                <Tab>Import</Tab>
                <Tab>From template</Tab>
            </TabList>

            <TabPanels>
                <TabPanel>
                    <ProjectAdd/>
                </TabPanel>
                <TabPanel>
                    <p>two!</p>
                </TabPanel>
                <TabPanel>
                    <p>three!</p>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </EditorPageTemplate>
}

export default observer(CreateNewProject)