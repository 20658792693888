import React from 'react';
import {Flex} from "@chakra-ui/react";
import LogoutButton from "../../../components/LogoutButton";

function Header({
                    sidebarOpen,
                    setSidebarOpen
                }: any) {
    return (
        <header
            className="sticky top-0 bg-white border-b border-slate-200 z-30  dark:bg-gray-900 dark:border-slate-800">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">
                    <Flex></Flex>
                    <Flex>
                        <LogoutButton/>
                    </Flex>
                </div>
            </div>
        </header>
    );
}

export default Header;