import {ComponentType, ReactElement} from "react";
import {DataProcess} from "context/nodeEngine/Data";
import {NodeProps} from "@reactflow/core/dist/esm/types/nodes";

type DataProcessConstructor<T extends DataProcess> = new (...args: any[]) => T;

export class Element<T extends DataProcess> {
    id: string;
    name: string;
    type: string;
    icon: ReactElement;
    viewComponent: ComponentType<NodeProps>;
    dataClass: DataProcessConstructor<T>;

    constructor(id: string,
                name: string,
                type: string,
                icon: ReactElement,
                viewComponent: ComponentType<NodeProps>,
                dataClass: DataProcessConstructor<T>
    ) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.icon = icon;
        this.viewComponent = viewComponent;
        this.dataClass = dataClass;
    }
}