import {useStores} from "../../../context/StoreContext";

const RestoreButton = () => {
    const {designerStore} = useStores();
    return (
        <>
            <button
                className={"btn btn-xs"}
                onClick={() => {
                    // Load from json file
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.accept = '.json';
                    input.onchange = (e) => {
                        let file = (e.target as HTMLInputElement).files![0];
                        let reader = new FileReader();
                        reader.readAsText(file, "UTF-8");
                        reader.onload = (e) => {
                            let json = JSON.parse(reader.result as string);
                            designerStore.restore(json);
                        }
                    }
                    input.click();
                }}>
                Load
            </button>

        </>
    );
}

export default RestoreButton;