import {HiPencil} from "react-icons/hi";
import {Element} from "../Element";
import {DataProcess} from "context/nodeEngine/Data";
import {Handle, NodeProps, Position} from "reactflow";
import NodeViewTemplate from "../NodeViewTemplate";
import {Code} from "@chakra-ui/react";
import {InputHandler} from "../Handlers";

const DATA_TYPE = "TEXT_OUTPUT";


export class TextOutputData implements DataProcess {
    dataTypeId = DATA_TYPE;
    id: string | undefined;
    name: string | undefined;
    description?: string;
    display: string = '';

    constructor(partial: Partial<TextOutputData>) {
        Object.assign(this, partial);
    }

    static default(): TextOutputData {
        return new TextOutputData({
            name: 'Output Text',
            display: 'Hello, World!'
        });
    }

    async process(val: {}) {
        console.log(val);
        if (val) {
            const prompt = Object.values(val).join(' ');
            this.display = prompt || this.display;
        }
    }

    get outputId() {
        return "display";
    }
}


const TextDisplayNodeViewComponent = ({data, selected, id}: NodeProps<TextOutputData>) => {
    return <NodeViewTemplate>
        <span className={"text-white px-4"}>Out {data.name}</span>
        <div className={"custom-node__select  px-4"}>
            <Code className={"w-full"}>
                {data.display}
            </Code>
        </div>
        <div className={""}>
            <InputHandler id={"input"} onConnect={(params) => console.log('handle onConnect', params)}>
                <span className={"text-white"}>Input</span>
            </InputHandler>
        </div>
    </NodeViewTemplate>
}


export const TextOutPutViewElement = new Element(
    DATA_TYPE,
    "Text Output",
    DATA_TYPE,
    <HiPencil/>,
    TextDisplayNodeViewComponent,
    TextOutputData,
)
