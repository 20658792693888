import {HiPencil} from "react-icons/hi";
import {Element} from "../Element";
import {DataProcess} from "context/nodeEngine/Data";
import {Handle, NodeProps, Position} from "reactflow";
import NodeViewTemplate from "../NodeViewTemplate";
import {Input} from "@chakra-ui/react";
import {DataType} from "../CommonData";
import {useStores} from "context/StoreContext";
import {InputHandler, OutputHandler} from "../Handlers";

const DATA_TYPE = "input-variable";


export class DataVariableData implements DataProcess {
    dataTypeId = DATA_TYPE;
    id: string | undefined;
    name: string | undefined;
    value: any;
    description?: string;
    type: DataType = DataType.OBJECT;
    isFromSource: boolean = false;

    constructor(partial: Partial<DataVariableData>) {
        Object.assign(this, partial);
    }

    static default(): DataVariableData {
        return new DataVariableData({
            name: 'Variable',
            type: DataType.TEXT,
            isFromSource: false,
            value: 'Default Value',
        });
    }

    async process(val: any) {
        return this.value;
    }

    get outputId() {
        return this.name || '';
    }

}

const DataSourceNodeViewComponent = (props: NodeProps<DataVariableData>) => {
    const {data, id} = props;
    const {designerStore} = useStores();
    const updateValues = ({name, value}: { name?: string, value?: any }) => {
        const newData = new DataVariableData({
            ...data,
            name: name || data.name,
            value: value || data.value,
        });
        designerStore.addNode({
            id: id,
            data: newData,
            nodeType: DATA_TYPE,
        });
    }

    return <NodeViewTemplate {...props}>
        <span className={"text-white px-4"}>Variable {`{${data.name}}`}</span>
        <OutputHandler id={data.id!} onConnect={({targetHandle}) => {
            if (targetHandle) {
                updateValues({name: targetHandle})
            }
        }}>
            <Input
                size={"sm"}
                borderRadius={"lg"}
                textColor={"whiteAlpha.900"}
                placeholder='Basic usage' value={data.value} onChange={(e) => {
                if (e.target.value) {
                    updateValues({value: e.target.value})
                }
            }}/>
        </OutputHandler>
    </NodeViewTemplate>;
}


export const InputVariableElement = new Element(
    DATA_TYPE,
    "Variable",
    DATA_TYPE,
    <HiPencil/>,
    DataSourceNodeViewComponent,
    DataVariableData,
)
