import {action, computed, makeAutoObservable, observable} from "mobx";
import {EdgeData, NodeData} from "../data/designer";
import RootStore from "./RootStore";
import {ComputeGraph, hasCircularDependency} from "../nodeEngine";
import {FuncGenService} from "../service/FuncGenService";
import {MultiDirectedGraph} from 'graphology';
import {Project} from "../models/Project";
import {data} from "autoprefixer";

export default class DesignerStore {

    // nodes
    @observable
    public nodes: NodeData[] = [];
    // edges
    @observable
    public edges: EdgeData[] = [];

    @observable
    public project?: Project;

    private nextNodeId = 0;

    get nextNodeKey() {
        return `node${++this.nextNodeId}`;
    }


    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    @action
    async updateGraph() {
        const graph = new MultiDirectedGraph();
        graph.on("nodeAttributesUpdated", ({key, type, attributes}) => {
            if (type === 'replace') {
                const node = this.nodes.find(node => node.id === key);
                if (node) {
                    node.data = attributes.data;
                }
            }
        });
        this.nodes.forEach(node => {
            graph.addNode(node.id, {...node} as NodeData);
        });

        this.edges.forEach(edge => {
            graph.addEdgeWithKey(edge.id, edge.source, edge.target, {...edge} as EdgeData);
        });

        await ComputeGraph(graph);
    }


    @action
    updateNodes(nodes: NodeData[]) {
        this.nodes = [...nodes];
    }

    @action
    updateEdges(edges: EdgeData[]) {
        const res = hasCircularDependency(this.nodes, edges);
        if (res) {
            this.rootStore.notificationStore.addErrorMessage("Circular dependency detected");
            return;
        }
        this.edges = [...edges];
        this.updateGraph().catch(console.error)
    }

    @action
    addNode({
                nodeType,
                data,
                id,
                position
            }: {
        nodeType: any,
        data: any,
        id?: string | null,
        position?: any | null
    }) {
        if (!id) {
            //console.log('addNode', nodeType, data, id, position);
            const nodeData =
                {
                    id: this.nextNodeKey,
                    data: data,
                    position: position || {x: 5, y: 5},
                    type: nodeType,
                } as NodeData;
            this.nodes = [...this.nodes, nodeData];
        } else {
            const nodeData = [...this.nodes].find(node => node.id === id);
            if (nodeData) {
                nodeData.data = data;
                nodeData.type = nodeType;
                this.nodes = [...this.nodes.filter(node => node.id !== id), nodeData];
            }
        }
        this.updateGraph().catch(console.error)
    }

    async save() {
        if (this.project) {
            await this.rootStore.projectStore.saveProject(this.project, {
                ...data,
            });
        }
    }

    @computed
    get data() {
        return {
            nodes: [...this.nodes],
            edges: [...this.edges],
            nextNodeId: this.nextNodeId
        };
    }

    @action
    restore(data: any) {
        this.nextNodeId = data.nextNodeId;
        this.nodes = [...data.nodes];
        this.edges = [...data.edges];
        // this.project = new Project(data.project);
        this.updateGraph().catch(console.error)
    }

    @action
    removeEdge(id: string) {
        this.edges = this.edges.filter(edge => edge.id !== id);
    }

    async saveProcess() {
        if (this.nodes.length === 0) {
            this.rootStore.notificationStore.addErrorMessage("Process is empty");
            return;
        }
        await FuncGenService.saveProcess({
            nodes: this.nodes,
            edges: this.edges
        });
    }

    async generateCode() {
        await this.saveProcess();
        await FuncGenService.generateCode();
    }

    @action
    setProject(project: Project) {
        this.project = project;
    }
}