import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";

import {getAuth, GithubAuthProvider,} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBI-7DRE_Qnn-3qj-mZt2yykHBUEdJsx3w",
    authDomain: "ceylon-ai-promot-eng.firebaseapp.com",
    databaseURL: "https://ceylon-ai-promot-eng-default-rtdb.firebaseio.com",
    projectId: "ceylon-ai-promot-eng",
    storageBucket: "ceylon-ai-promot-eng.appspot.com",
    messagingSenderId: "986062141504",
    appId: "1:986062141504:web:69b3ba5320379c6cb107a5",
    measurementId: "G-T3VMJJ9HNJ"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GithubAuthProvider();
const db = getFirestore();

export {auth, provider, db} 