import {Box, Grid, GridItem, Heading} from "@chakra-ui/react";
import React, {useMemo} from "react";
import CreateButtonTemplate from "./CreateButtonTemplate";
import {ElementRegistry} from "context/data/designer";

interface NodeCreatePanelProps {
    text: string;
}

const TitleComponent = ({text}: NodeCreatePanelProps) => {
    return (
        <GridItem>
            <Heading as='h6' size='xs'>{text}</Heading>
        </GridItem>
    )
}

const NodeCreatePanel = () => {
    const elements = useMemo(() => {
        return [
            ...ElementRegistry
        ];
    }, [])
    return (
        <Box bg={"gray.900"} p={2} pt={8} border={"1px"} borderColor={"gray.600"} borderRadius={"lg"} rounded={"lg"}>
            <Grid gap={4} p={4}>
                <TitleComponent text={"Variables"}/>

                {elements.map((e) => {
                    return (
                        <CreateButtonTemplate key={e.id} text={e.name} componentType={e.id}
                                              icon={e.icon}/>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default NodeCreatePanel;