import RootStore from "./RootStore";
import {action, makeAutoObservable, observable} from "mobx";

interface IMessage {
    severity: string;
    summary: string;
    detail: string;
}

export class NotificationStore {

    @observable
    messageQueue: IMessage[] = [];
    @observable
    blockUI: boolean = false;

    @observable
    processingQueue: any[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
    }

    @action
    addError(e: any) {
        this.addMessage({
            severity: "error",
            summary: "Error",
            detail: e.message
        });
    }


    @action
    addErrorMessage(e: any) {
        this.addMessage({
            severity: "error",
            summary: "Error",
            detail: e
        });
    }

    @action
    addSuccess(message: string) {
        this.addMessage({
            severity: "success",
            summary: "Success",
            detail: message
        });
    }

    @action
    popMessages(): IMessage[] {
        const messages = [...this.messageQueue];
        this.messageQueue = [];
        return messages;
    }

    @action
    private addMessage(param: IMessage) {
        this.messageQueue = [...this.messageQueue, param];
    }

    @action
    toggleBlockUI() {
        this.blockUI = !this.blockUI;
    }

    @action
    unblockUI() {
        this.blockUI = false;
    }

    @action
    addProcessingQueue(promise: any) {
        this.processingQueue = [...this.processingQueue, promise];
    }

    @action
    removeProcessingQueue(promise: any) {
        this.processingQueue = this.processingQueue.filter((p: any) => p !== promise);
    }

    addWarning(message: string) {
        this.addMessage({
            severity: "warn",
            summary: "Warning",
            detail: message
        });
    }
}